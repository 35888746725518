window.app.service('FileSystemService', ['ErrFactory', 'RequestPermissionsService', '$rootScope',
                                function (ErrFactory,   RequestPermissionsService,   $rootScope) {
  class Directory {
    #url = '';

    constructor(url) {
      this.#url = url;
    }

    toString() {
      if ( (new RegExp(`^${Directory.APP_DIR}`)).test(this.#url) ) {
        return this.#url.replace(Directory.APP_DIR,'');
      } throw new TypeError(`Don't have relative path ${this.#url}`);
    }

    async dirEntry () {
      return await new Promise((res,rej) => {
        window.resolveLocalFileSystemURL(this.#url, res, (err) => rej(new ErrFactory.FileSystemError(err)));
      });
    }

    async nativeURL () {
      let dirEntry: any = await this.dirEntry();
      return dirEntry.nativeURL;
    }

    async clearDir () {
      let entry: any = await this.dirEntry();
      let directoryReader = entry.createReader();

      let entries: any = await new Promise((res,rej) => directoryReader.readEntries(res,rej));

      await Promise.all(
        entries.map((entry) => {
          if (entry.isFile) return new Promise((res,rej) => entry.remove(res,rej))
        })
      )
      .then((l) => console.log('remove cache',l));
    }


    //*** for keep compatibility
    [Symbol.toPrimitive](hint) {
      if (hint === 'string') return this.toString();
      else if (hint === 'default') return this.toString();
      else throw new TypeError(`convert to ${hint}`);
    }
    //*** for keep compatibility
    get [Symbol.toStringTag]() {
      return 'String';
    }

    static fromRelativePath (path) {
      return new Directory(Directory.APP_DIR + path);
    }

    static get APP_DIR () {
      if (window.cordova) return cordova.file.dataDirectory
      else return '';
    }
  }

  class FileSystem {
    static PATH: any;
    static FS: any;
    static ROOT_PATH: string;
    static ERROR_FS: string;
    static isSupportFS() {
      return Boolean(FileSystem.FS);
    }

    static getPathByType (type) {
      switch (type) {
        case 'cache': return FileSystem.PATH.CACHE;
        default: return FileSystem.PATH.MAIN;
      }
    }

    static getFullPathByType (type) {
      if (deviceIsAndroid) {
        switch (type) {
          case 'exports':
            return `${window.cordova.file.externalDataDirectory}`;
          default:
            return FileSystem.ROOT_PATH + FileSystem.getPathByType(type);
        }
      } else return FileSystem.ROOT_PATH + FileSystem.getPathByType(type);
    }

    static getFilePath (dirType, fileName) {
      return FileSystem.getPathByType(dirType) + fileName;
    }

    static async getFileByPath (path) {
      return await getFS()
      .then((fs) => {
        if ( this.isRelativePath(path) ) {
          path = FileSystem.ROOT_PATH + path;
        }

        return new Promise((res,rej) => window.resolveLocalFileSystemURL(path,res,rej));
      })
      .catch((err) => {
        throw new ErrFactory.FileSystemError(err);
      })
    }

    static getFileByName(name, type) {
      const path = this.getPathByType(type) + name;
      return this.getFileByPath(path);
    }

    static isRelativePath (path) {
      let reg = new RegExp(`^${cordova.file.applicationStorageDirectory}`);
      return !reg.test(path);
    }

    static async downloadByURL(url, dirType, fileName, headers) {
      try {
        const fs = await getFS();
        const filePath = FileSystem.ROOT_PATH + this.getFilePath(dirType, fileName);
        const fileTransfer = new FileTransfer();

        let response = await new Promise((res,rej) => {
          fileTransfer.download(url, filePath, res, rej, false, { headers: headers ? headers : {} });
        })

        return response;

      } catch (e) {
        //console.error(e,url,fileName);
        throw new ErrFactory.FileSystemError(e);
      }
    }

    static async removeFileByName(name, type){
      let nativeURL = await this.getPathByType(type).nativeURL();
      return await this.removeFileByPath(nativeURL + name);
    }

    static async removeFileByPath(path) {
      return await FileSystem.getFileByPath(path)
      .then((fileEbtry) => {
        return new Promise((res,rej) => fileEbtry.remove(res,rej))
      })
      .catch((err) => {
        throw new ErrFactory.FileSystemError(err);
      });
    }

    static async removeByFileEntry (fileEntry:any) {
      return await (new Promise((res,rej) => fileEntry.remove(res,rej)))
      .catch((err) => Promise.reject(new ErrFactory.FileSystemError(err)));
    }

    static async cleanCacheFolder () {
      await getFS()

      return await FileSystem.PATH.CACHE.clearDir()
      .then(() => {
        if (FileSystem.PATH.EXTERNAL_CACHE) return FileSystem.PATH.EXTERNAL_CACHE.clearDir()
      })
      .catch((err) => {
        console.error(err);
        throw new ErrFactory.FileSystemError(err);
      });
    }

    static chooseFile(from) {
      if (!navigator.camera) return Promise.reject(new ErrFactory('errors.scanNotSupported', true));

      let cameraOptions: any = {};
      cameraOptions.quality = 80;
      cameraOptions.correctOrientation = true;

      if (from === 'camera') {
        switch ($rootScope.imageResolution) {
          case 'small':
            cameraOptions.targetWidth = 512;
            cameraOptions.targetHeight = 512;
            break;
          case 'medium':
            cameraOptions.targetWidth = 1024;
            cameraOptions.targetHeight = 1024;
            break;
          case 'original':
            // cameraOptions.targetWidth = 2048;
            // cameraOptions.targetHeight = 2048;
            break;
          default:
            cameraOptions.targetWidth = 1024;
            cameraOptions.targetHeight = 1024;
            $rootScope.imageResolution = 'medium';
        }
      }

      if (from === 'camera') {
        cameraOptions.sourceType = Camera.PictureSourceType.CAMERA;
        cameraOptions.encodingType = Camera.EncodingType.JPEG;
        cameraOptions.destinationType = Camera.DestinationType.FILE_URI;
        cameraOptions.correctOrientation = true;
        cameraOptions.saveToPhotoAlbum = true;
      } else {
        cameraOptions.destinationType = Camera.DestinationType.FILE_URI;
        cameraOptions.sourceType = Camera.PictureSourceType.PHOTOLIBRARY;
        cameraOptions.correctOrientation = true;
      }

      return new Promise((res,rej) => {
        navigator.camera.getPicture(res,rej,cameraOptions);
      })
      .then((fileURI) => getNtivePath(fileURI))
      .then((path) => checkFileExtension(path))
      .then((path: any) => {
        if (!window.resolveLocalFileSystemURL) {
          return Promise.reject(new ErrFactory('errors.fileSystem'));
        }

        return new Promise((res,rej) => {
          if (deviceIsIOS) path = path.replace(/^http:\/\/localhost:6985\/.*\/tmp\//,cordova.file.tempDirectory);
          window.resolveLocalFileSystemURL(path, (entry) => res(entry), (err) => rej(new ErrFactory('errors.fileSystem')));
        })
        .then((entry) => {
          return this.moveFileByFileEntry(entry, 'attachments');
        });
      });
    }

    static copyFile (entry, type, fileName) {
      return getFS()
          .then(() => {
            if (!window.resolveLocalFileSystemURL) return Promise.reject(new ErrFactory('errors.fileSystem'));
            const dirPatn = this.ROOT_PATH + this.getPathByType(type);

            return new Promise((res,rej) => {
              window.resolveLocalFileSystemURL(dirPatn, (dirEntry) => {
                entry.copyTo(dirEntry, fileName, (newEntry) => {
                  //console.log(`Copy to: ${newEntry.fullPath}`);
                  res(newEntry);
                }, rej);
              }, (err) => rej(new ErrFactory('errors.fileSystem')))
            });
          })
    }

    static async moveFileByFileEntry (entry, type, newFileName?) {
      try {
        const fs = await getFS();

        const dirEntry = await this.getPathByType(type).dirEntry();

        const newFileEntry = await new Promise((res,rej) => {
          entry.moveTo(dirEntry, newFileName,res,rej)
        });

        return newFileEntry;

      } catch (e) {
        console.error(e);
        throw new ErrFactory.FileSystemError(e);
      }
    }

    static async writeFileToSystem (data, fileName, type) {
      if (data instanceof Blob) return await FileSystem.saveBlob(data, fileName, type);
      else if (data instanceof ArrayBuffer) return await FileSystem.saveBuffer(data, fileName, type);
      else if (data.buffer && data.buffer instanceof ArrayBuffer) return await FileSystem.saveBuffer(data.buffer, fileName, type);
      else throw new ErrFactory;
    }

    static async saveBuffer(data, fileName, type) {
      const dirEntry = await getFS()
      .then(() => {
        return this.getPathByType(type).dirEntry()
      });

      await new Promise((res,rej) => dirEntry.getFile(fileName, { create: false },res,rej))
      .then((fn: any) => new Promise((res,rej) => fn.remove(res,rej)))
      .catch(() => {});

      let fileEntry: any = await new Promise((res,rej) => dirEntry.getFile(fileName, { create: true, exclusive: true },res,rej));
      let fileWriter: any = await new Promise((res,rej) => fileEntry.createWriter(res,rej));

      const BLOCK_SIZE = 512*1024;
      while (fileWriter.length < data.byteLength) {
        await new Promise((res,rej) => {
          let delta = Math.min(BLOCK_SIZE,data.byteLength - fileWriter.length);
          fileWriter.onerror = rej;
          fileWriter.onwrite = res;

          fileWriter.seek(fileWriter.length);
          fileWriter.write(data.slice(fileWriter.length, fileWriter.length + delta));
        });

        fileWriter.onerror = null;
        fileWriter.onwrite = null;
      }

      return fileEntry;
    }

    static async saveBlob(blob,fileName,type) {
      try {
        await getFS();

        const cacheDirEntry = await this.getPathByType('cache').dirEntry();

        if (type !== 'cache') { // remove if existed
          const dirEntry = await this.getPathByType(type).dirEntry();
          await new Promise((res,rej) => dirEntry.getFile(fileName, { create: false },res,rej))
          .then((fn: any) => {
            console.log(fn);
            return new Promise((res,rej) => fn.remove(res,rej))
          })
          .catch(() => {});
        }

        let fileEntry:any = await new Promise((res,rej) => cacheDirEntry.getFile(fileName, { create: true, exclusive: true },res,rej));
        let fileWriter:any = await new Promise((res,rej) => fileEntry.createWriter(res,rej));

        const BLOCK_SIZE = 512*1024;

        while (fileWriter.length < blob.size) {
          await new Promise((res,rej) => {
            let delta = Math.min(BLOCK_SIZE,blob.size - fileWriter.length);
            fileWriter.onerror = rej;
            fileWriter.onwrite = res;

            fileWriter.seek(fileWriter.length);
            //fileWriter.write(blob);
            fileWriter.write(blob.slice(fileWriter.length, fileWriter.length + delta));
          });

          fileWriter.onerror = null;
          fileWriter.onwrite = null;
        }

        fileWriter = blob = null;

        if (type !== 'cache') {
          fileEntry = await  FileSystem.moveFileByFileEntry(fileEntry,type, fileName);
        }
        return fileEntry;
      } catch (e) {
        FileSystem.cleanCacheFolder();
        console.error(e);
        throw new ErrFactory.FileSystemError(e);
      }
    }

    static initFS () { return  getFS(); }

    static dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(',')[1]);

      let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString} );
    }

    static readContent (fileEntry, responseType = 'text') {
      // responseType: '' = text; "arraybuffer"; "blob"; "document"; "json"; "text"
      return new Promise((res,rej) => {
        let xhr: any = new XMLHttpRequest();
        xhr.responseType = responseType;
        xhr.onload = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200 || xhr.status === 0) {
              res(xhr.response);
            } else {
              rej(xhr.statusText);
            }
          }
        };

        xhr.onerror = function (e) {
          rej(e);
        };

        xhr.open('GET', fileEntry.toURL());
        xhr.send();
      })
    }

    static blobToDataURI (blob) {
      return new Promise((res,rej) => {
        let fr = new FileReader;

        fr.onloadend = function () {
          res(fr.result);
        };

        fr.onerror = function (e) {
          rej(e);
        };

        fr.readAsDataURL(blob);
      })
    }

    static shareFile(fileEntry, fileName, project:any = {}) {
      let path = fileEntry.nativeURL;

      return (new Promise((res, rej) => {
        if (deviceIsIOS) path = path.replace(/^http:\/\/localhost:6985\/local-filesystem/, "file://");

        let option = {
          message: project.share_email_text || '',
          subject: project.share_email_subject || fileName,
          files: [path]
        };

        try {
          return window.plugins.socialsharing.shareWithOptions(option, res, rej);
        } catch (e) {
          return rej(e);
        }
      }))
      //.then(() => FileSystem.removeFileByPath(path));
    }

    static simpleShareFile(fileEntry) {
      return Promise.resolve()
      .then(() => {
        let path = fileEntry.nativeURL;
        if (deviceIsIOS) path = path.replace(/^http:\/\/localhost:6985\/local-filesystem/, "file://");
        return window.plugins.socialsharing.share(null, null, [path]);
      });
    }

    static loadImageByURL (url) {
      return new Promise(function (res, rej) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200 || xhr.status === 0) {
              res(xhr.response);
            } else {
              rej(xhr.statusText);
            }
          }
        };

        xhr.onerror = function (e) {
          rej(e);
        };

        xhr.open('GET', url);
        xhr.send();
      });
    };

  }

  function checkFileExtension(path) {
    const rgx = /.*\.(png|jpeg|jpg)$/;

    return new Promise((res,rej) => {
      if (rgx.test(path)){
        res(path);
      }else{
        rej(new ErrFactory('native_error.messages.unsupported_format_of_image'));
      }
    });
  }

  function getNtivePath(path) {
    if ( !window.FilePath ) return Promise.resolve(path);

    return new Promise((res,rej) => {
      if (device.platform === 'Android') {
        window.FilePath.resolveNativePath(path, (result) => {
          const reg = /^file:/;
          if ( !reg.test(result) ) {
            result = `file://${result}`;
          }
          res(result);
        }, (err) => rej(err));
      }else{ res(path) }
    });

  }

  let reqestFS = null;

  function getFS() {
    if (reqestFS instanceof Promise) {
      return reqestFS;
    }else{
      reqestFS = setFS();
      return reqestFS;
    }
  }

  function setFS() {
    return RequestPermissionsService.storage()
    .then(() => {
      if (!FileSystem.ROOT_PATH) {
        if (window.cordova && window.cordova.file) Object.defineProperty(FileSystem, 'ROOT_PATH', { enumerable: true, value: window.cordova.file.dataDirectory });
      }
    })
    .then((fileSystem) => {
      const PATH = {};
      if (window.cordova) {
        Object.defineProperties(PATH, {
          CACHE: { enumerable: false, value: new Directory(cordova.file.cacheDirectory) },
          DATA: { enumerable: false, value: new Directory(cordova.file.dataDirectory) }
        });

        if (cordova.file.externalCacheDirectory) {
          Object.defineProperties(PATH, {
            EXTERNAL_CACHE: { enumerable: false, value: new Directory(cordova.file.externalCacheDirectory) }
          });
        }
      }

      Object.defineProperty(FileSystem, 'PATH', { value: PATH });

      return fileSystem;
    })
    .then(() => {
      if ( FileSystem.ERROR_FS ) return Promise.reject(FileSystem.ERROR_FS);
      if ( FileSystem.FS ) return FileSystem.FS;

      if (window.requestFileSystem) {
        return FileSystem.PATH.DATA.dirEntry()
        .then((dir) => {
          Object.defineProperty(FileSystem, 'FS', { value: dir.filesystem });
          return FileSystem.FS;
        });

      } else {
        let err = new ErrFactory.FsIsNotDefined;
        Object.defineProperty(FileSystem, 'ERROR_FS', { value: err });
        console.error(err);
        return Promise.reject(err);
      }

    })
  }

  return FileSystem;
}]);
